import { render, staticRenderFns } from "./S2_P2_Address.vue?vue&type=template&id=643ba5e0&scoped=true&"
import script from "./S2_P2_Address.vue?vue&type=script&lang=js&"
export * from "./S2_P2_Address.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643ba5e0",
  null
  
)

export default component.exports