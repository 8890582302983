<template>
  <div class="vOverview">
    <h1 class="text-center">{{ $t('pages.overview.headline') }}</h1>
    <steps :steps="[]" />

    <div class="container-fluid container-md" v-if="!preConditions()">
      <div class="alert alert-danger">
        <strong>Error:</strong> Preconditions not valid

        Preconditions
        <ul>
          <li>select studio</li>
          <li>select contract</li>
        </ul>
      </div>
    </div>

    <div class="container-fluid container-lg" v-if="preConditions()">
      <div class="row">
          <div class="col-12 col-md-6">
              <div class="hr mx-0"><span>{{ $t('pages.overview.yourData')}}</span></div>
              <ul class="overviewList">
                  <li>
                    <span class="title">{{ $t('common.yourStudio') }}:</span>
                    <span class="value">{{ studio.name }} </span>
                    <a href="#" @click="$store.commit('SET_STEP', {step: 0})" >{{$t('common.edit')}}</a>
                  </li>
                  <li>
                    <span class="title">{{ $t('common.yourPlan') }}:</span>
                    <span class="value"> {{ contract.Bezeichnung }} ({{ contract.vertBeitrag | formatCurrency}})</span>
                    <a href="#" @click="$store.commit('SET_STEP', {step: 1})">{{$t('common.edit')}}</a>
                  </li>
                  <li>
                    <span class="title">{{ $t('common.yourAddition') }}:</span>
                    <span class="value" v-if="addition">{{ addition.bezeichnung_current_language }} ({{ addition.zusaBeitrag | formatCurrency }})</span>
                    <span class="value" v-else>{{ $t('common.noAddition') }}</span>
                    <a href="#" @click="$store.commit('SET_STEP', {step: 2})">{{$t('common.edit')}}</a>
                  </li>
                  <li class="d-sm-none">
                    <span class="title">{{ $t('footer.paymentOptions')}}:</span>
                    <div class="paymentIcons row">
                      <div class="col">
                        <img src="/shop/img/payments/paypal-logo.svg">
                      </div>
                      <div class="col">
                        <img src="/shop/img/payments/visa-logo.svg">
                      </div>
                      <div class="col">
                        <img src="/shop/img/payments/mastercard-logo.svg">
                      </div>
                      <div class="col">
                        <img src="/shop/img/payments/post-finance-logo.svg">
                      </div>
                      <div class="col">
                        <img src="/shop/img/payments/e-finance-logo.svg">
                      </div>
                    </div>
                  </li>
                  <li class="bg-white">
                    <div class="by bg-white">
                      <strong>{{ $t('pages.overview.voucherHintHeadline') }}</strong>
                      <p>{{ $t('pages.overview.voucherHint') }}</p>
                    </div>
                  </li>
              </ul>
          </div>
          <div class="col">
            <div class="h-100 d-flex flex-column">
                <div class="hr mx-0"><span>{{$t('widget.basket.totalAmountFull')}}</span></div>
                <basket></basket>
                <button class="btn btn-primary btn-block btn-xxl" @click="$store.commit('SET_STEP', {step: 4})">{{$t('common.checkout')}}</button>
              </div>
          </div>
      </div>
    </div>
    <footer-link-bar></footer-link-bar>
    <Footer :button-hidden="true"></Footer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Basket from "@/components/Basket";
import Steps from "@/components/StepsPart1";
import FooterLinkBar from "@/components/FooterLinkBar";
import Footer from "@/components/Footer";

export default {
  name: "Overview",
  components: {Basket,Steps, FooterLinkBar, Footer},
  computed: mapState(["currentStep", "studio", "contract", "addition"]),
  mounted(){
    this.$store.commit("SET_STEP", {step:3});
  },
  methods:{
    preConditions(){
      if(this.studio === undefined || !this.studio) return false;
      if(this.contract === undefined || !this.contract) return false;

      return true;
    },
  }
}
</script>

<style scoped>

</style>