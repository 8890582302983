export default {
    wohlen: "Corsi disponibili dal 5 luglio.",
    common:{
        dateFormat: "DD.MM.YYYY",
        currency:  "CHF {value}",
        choose:     "Scelga",
        next:       "Continua",
        previous:   "Indietro",
        bestChoice: "La scelta migliora",
        and:        "e",
        or:         "o",
        edit:       "Modifica",
        basket:     "Carrello",
        yourStudio: "Il tuo club",
        yourPlan:   "Il tuo abbonamento",
        yourAddition: "I tuoi extra",
        noAddition: "Continuare senza extra",
        days : "Giorno | 1 giorno | {count} giorni",
        months : "Mese | 1 mese | {count} mesi",
        checkout: "Alla cassa",
    },
    widget:{
        miniors: {
            headline: "Minori",
            minors1: "Non sei ancora maggiorenne?",
            minors2: "Chiedi al tuo genitore o tutore di compilare questo {Modulo}.",
            form: "Modulo",
            formUrl: "https://basefit.ch/wp-content/uploads/2021/04/IT_U18_Formular_Abo.pdf",
            minors3: "Porta la tua carta d'identità e il tuo tutore al tuo primo allenamento e andiamo!",
        },
        studio:{
          searchPlaceholder : "Dove vuoi allenarti",
          clubList : "Elenco club",
          mapView : "Vista mappa"
        },
        basket:{
            totalAmountFull: "IMPORTO TOTALE",
            totalAmount: "Totale",
        },
        user:{
          genderFemale : "Sig.ra",
          genderMale : "Uomo",
          genderDivers : "Divers",
          firstname : "Nome",
          lastname : "Cognome",
          email : "E-mail",
          phone : "Telefono",
          birthday : "Compleanno",
        },
        address:{
          street : "Via",
          streetno : "Numero",
          zipcode : "C. POSTALE",
          city : "Città",
          country : "Nazione",
        },
        membership:{
          membership : "La tua iscrizione",
          startOfMembership : "Inizio della tua iscrizione",
          trainingsStart : "Formazione inizia {date} ",
          rightAway : "subito",
        }
    },
    footer:{
        backToHomepage : "Torna al sito web",
        imprint : "Impronta",
        dataProtection : "Privacy",
        paymentOptions : "Pagamento",
    },
    steps: {
      step1_1 : 'Scegli club',
      step1_2 : "Scegli abbonamento",
      step1_3 : "Aggiunte",
      step1_4 : "Sommario",
      step2_1 : "Dati",
      step2_2 : "Indirizzo",
      step2_3 : "Pagamento",
      step2_4 : "Conferma",
    },
    pages:{
        selectClub:{
            headline: "Scegli il tuo club",
        },
        selectContract: {
            headline: "Scegli l´abbonamento",
            choosePlan : "Scegli la tua iscrizione",
            chooseRuntime : "Scegli il tuo termine",
            basisPlan : "Offerta di base",
            aktionPlan: "Offerta speciale",
            premiumPlan : "Migliore offerta",
            planPoints: [
              "App Basefit.ch con utilizzo live",
              "Fitness su richiesta",
              "Accesso a tutti i club",
              "Un amico si allena gratis nel weekend",
              "Periodo di cancellazione",
              "Metti in pausa la tua iscrizione",
              "Tassa d'iscrizione",
              "Periodo di cancellazione",
            ],
            tooltips:{
                weekendFriend: "I membri Premium hanno la possibilità di portare una persona in più (\"Amico\") con loro per l'uso condiviso delle strutture nei fine settimana (sabato e domenica) senza costi aggiuntivi. Vedere Termini e condizioni a pagina 6 per ulteriori dettagli.",
                freezeMembership: "Sono consentiti un ´time-stop´ giustificato fino a 9 mesi (ad esempio servizio militare, malattia, soggiorno professionale all'estero) e un\" time-stop \"infondato fino a 3 mesi all'anno. Vedere termini e condizioni p.13 per maggiori dettagli.",
            }
        },
        selectAddition:{
            headline: "Completa il tuo abbonamento",
            unselectAddition: "Continuare senza extra",
        },
        overview:{
            headline: "Il tuo riassunto",
            yourData: "I tuoi dati",
            voucherHintHeadline:  "Hai un buono?",
            voucherHint:  "Puoi utilizzarlo nel prossimo passo.",
        },
        selectData:{
            headline: "Cassa",
            dataProtection: "Protezione Dati",
                checkboxDataPolice: "Accetto {DP_URL} di basefit.ch.",
                checkboxNewsletter: "Sì, desidero informazioni pubblicitarie e informazioni su altri prodotti basefit.ch e dei suoi partner.",
                footerActionText: "Per favore riempi",
                yourData: "I tuoi dati",
            },
        selectAddress:{
            headline: "Cassa",
            yourAddress: "Il tuo indirizzo",
                yourMembership: "Abbonamento",
                howDoYouKnow: "Come ci conosci?",
                footerName: "Ciao {fname}",
                footerActionText: "Per favore riempi",
            },
            selectPayment:{
                headline: "Cassa",
                yourPaymentOptions: "I tuoi metodi di pagamento",
                //healthInsuranceRefund: "Rimborso assicurazione sanitaria",
                healthInsuranceRefund: "Risparma e stai sano",
                checkboxHealthInsuranceRefund: "Sì, desidero beneficiare del programma bonus Swica - rimborso fino a CHF 600 - ed essere contattato. Acconsento alla trasmissione dei miei dati a Swica. Puoi trovare ulteriori informazioni su {url}.",
                checkboxUrlText: "qui",
                footerName: "Hallo {fname}",
                footerActionText: "Nur noch ein Schritt",
           },
        checkOut:{
            headline: "Cassa",
            yourOverview: "Il tuo riassunto",
            yourData: "I tuoi dati",
            address: "Indirizzo",
            yourMembershipStart : "Inizio della tua iscrizione",
            paymentInformation : "Pagamento",
            couponRedeem : "Riscattare",
            coupon : "Codice coupon",
            couponAccept : "Il codice coupon {coupon} è stato attivato.",
            couponDecline : "Il codice coupon {coupon} è stato rifiutato.",
            orderForFee : "Acquista ora",
            checkboxAGB : "Accetto i {url_agb} e la {url_dataprotection} di basefit.ch.",
            checkboxURLAGB : "termini e condizioni",
            checkContract: "Accetto che il contratto possa essere prorogato di un ulteriore mese alle condizioni generali se non viene disdetto almeno 10 giorni lavorativi prima della scadenza mediante comunicazione scritta.",
            checkContract12: "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkHouseRules: "Accetto le {houseRules} di basefit.ch.",
            houseRules: "regole della casa",
        },
    },
    fields:{
        fname : "Nome",
        lname : "Cognome",
        birthday: "Compleanno",
        phone: "Telefono",
        email: "E-Mail",
        street: "Via",
        streetNo: "Hausnummer",
        zipcode: "C. POSTALE",
        city: "Città",
    },
    validations: {
        "messages": {
            "alpha": "Il campo {_field_} può contenere solo caratteri alfabetici",
            "alpha_num": "Il campo {_field_} può contenere solo caratteri alfanumerici",
            "alpha_dash": "Il campo {_field_} può contenere caratteri alfa-numerici così come lineette e trattini di sottolineatura",
            "alpha_spaces": "Il campo {_field_} può contenere solo caratteri alfanumerici così come spazi",
            "between": "Il campo {_field_} deve essere compreso tra {min} e {max}",
            "confirmed": "Il campo {_field_} non corrisponde",
            "digits": "Il campo {_field_} deve essere numerico e contenere esattamente {length} cifre",
            "dimensions": "Il campo {_field_} deve essere {width} x {height}",
            "email": "Il campo {_field_} deve essere un indirizzo email valido",
            "excluded": "Il campo {_field_} deve avere un valore valido",
            "ext": "Il campo {_field_} deve essere un file valido",
            "image": "Il campo {_field_} deve essere un'immagine",
            "integer": "Il campo {_field_} deve essere un numero",
            "is_not": "Il campo {_field_} non è valido",
            "length": "La lunghezza del campo {_field_} deve essere {length}",
            "max_value": "Il campo {_field_} deve essere minore o uguale a {max}",
            "max": "Il campo {_field_} non può essere più lungo di {length} caratteri",
            "mimes": "Il campo {_field_} deve avere un tipo di file valido",
            "min_value": "Il campo {_field_} deve essere maggiore o uguale a {min}",
            "min": "Il campo {_field_} deve avere almeno {length} caratteri",
            "numeric": "Il campo {_field_} può contenere solo caratteri numerici",
            "oneOf": "Il campo {_field_} deve avere un valore valido",
            "regex": "Il campo {_field_} non ha un formato valido",
            "required": "Il campo {_field_} è richiesto",
            "required_if": "Il campo {_field_} è richiesto",
            "size": "Il campo {_field_} deve essere inferiore a {size}KB",
            "double": "Il campo {_field_} deve essere un decimale valido"
        }
    },
    promo: {
        desktop: "Ottieni uno sconto del 50% sul tuo Base1 Core Abbonamento e paga nessuna tassa d´iscrizione: Usa il codice promozionale <b>BASEFIT50</b> nell'ultimo step nel webshop.",
        mobile: "Usa il codice promozionale <b>BASEFIT50</b> per ottenere -50% sul tuo Base1 Core abbonamento",
    }
};
