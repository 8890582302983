export default {
    wohlen: "Kurse ab 5. Juli verfügbar.",
    common: {
        dateFormat: "DD.MM.YYYY",
        currency: "CHF {value}",
        choose: "Wählen",
        next: "Weiter",
        previous: "Zurück",
        bestChoice: "Beste Wahl",
        and: "und",
        or: "oder",
        edit: "Bearbeiten",
        basket: "Warenkorb",
        yourStudio: "Dein Club",
        yourPlan: "Deine Mitgliedschaft",
        yourAddition: "Dein Zusatz",
        noAddition: "Kein Zusatz gewählt",
        days: "Tag | 1 Tag | {count} Tage",
        months: "Monat | 1 Monat | {count} Monate",
        checkout: "Zur Kasse",
    },
    widget: {
        miniors: {
            headline: "Minderjährige",
            minors1: "Du bist noch nicht volljährig?",
            minors2: "Lass dieses {Formular} von deinem Erziehungsberechtigten ausfüllen.",
            form: "Formular",
            formUrl: "https://LakeSide Sportsclub/wp-content/uploads/2021/04/DE_U18_Formular_Abo.pdf",
            minors3: "Bring deinen Ausweis und Erziehungsberichtigten zum ersten Training mit und los geht’s!",
        },
        studio: {
            searchPlaceholder: "Wo möchtest du trainieren?",
            clubList: "Clubliste",
            mapView: "Kartenansicht",
        },
        basket: {
            totalAmountFull: "Gesamtbetrag",
            totalAmount: "Gesamt",
        },
        user: {
            genderFemale: "Frau",
            genderMale: "Mann",
            genderDivers: "Divers",
            firstname: "Vorname",
            lastname: "Nachname",
            email: "E-Mail",
            phone: "Telefon",
            birthday: "Geburtstag",
        },
        address: {
            street: "Straße",
            streetno: "Hausnummer",
            zipcode: "PLZ",
            city: "Stadt",
            country: "Land",
        },
        membership: {
            membership: "Mitgliedschaft",
            startOfMembership: "Beginn deiner Mitgliedschaft",
            trainingsStart: "Trainingsstart: {date}",
            rightAway: "sofort",
        },
    },
    footer: {
        backToHomepage: "Zurück zur Webseite",
        imprint: "Impressum",
        dataProtection: "Datenschutz",
        paymentOptions: "Zahlungsart",
    },
    steps: {
        step1_1: "Club wählen",
        step1_2: "Mitgliedschaft wählen",
        step1_3: "Zusätze",
        step1_4: "Übersicht",
        step2_1: "Daten",
        step2_2: "Adresse",
        step2_3: "Bezahlung",
        step2_4: "Bestätigung",
    },
    pages: {
        selectClub: {
            headline: "Wähle deinen Club",
        },
        selectContract: {
            headline: "Wähle dein Abo",
            choosePlan: "Wähle deine Mitgliedschaft",
            chooseRuntime: "Wähle deine Laufzeit",
            basisPlan: "Basis Angebot",
            aktionPlan: "Aktionsangebot",
            premiumPlan: "Bestes Angebot",
            planPoints: [
                "LakeSide Sportsclub App inkl. Live Auslastung",
                "Fitness On-Demand",
                "Zugang zu allen Clubs",
                "Ein Freund trainiert gratis am WE",
                "Kündigungsfrist",
                "Mitgliedschaft pausieren",
                "Einschreibegebühr",
                "Kündigungsfrist",
            ],
            tooltips: {
                weekendFriend:
                    'Premium Mitglieder haben die Möglichkeit am Wochenende (Samstag und Sonntag) ohne zusätzliche Gebühr eine zusätzliche Person ("Friend") zur gemeinsamen Mitbenutzung der Einrichtungen mitzubringen. Siehe AGB p.6 für weitere Details.',
                freezeMembership:
                    "Ein begründeten ´Time-Stopp´ bis zu 9 Monate (zum Beispiel Militärpflicht, Krankheit, Beruflicher Auslandsaufenthalt) und unbegründeten ´Time-Stopp´ bis zu 3 Monate pro Jahr erlaubt. Siehe AGB p.13 für weitere Details.",
            },
        },
        selectAddition: {
            headline: "Wähle deine Zusatz",
            unselectAddition: "Keine Zusätze wählen",
        },
        overview: {
            headline: "Deine Übersicht",
            yourData: "Deine Angaben",
            voucherHintHeadline:  "Du hast einen Gutschein?",
            voucherHint:  "Diesen kannst du im nächsten Schritt einlösen.",
        },
        selectData: {
            headline: "Kasse",
            dataProtection: "Datenschutz",
            checkboxDataPolice: "Ich stimme den {DP_URL} von LakeSide Sportsclub zu.",
            checkboxNewsletter:
                "Ja, ich möchte Werbeinformationen und Informationen zu anderen Produkten von LakeSide Sportsclub und seinen Partnern erhalten.",
            footerActionText: "Bitte ausfüllen",
            yourData: "Deine Daten",
        },
        selectAddress: {
            headline: "Kasse",
            yourAddress: "Deine Adresse",
            yourMembership: "Mitgliedschaft",
            howDoYouKnow: "Woher kennst du uns?",
            footerName: "Hallo {fname}",
            footerActionText: "Bitte deine Adresse eingeben",
        },
        selectPayment: {
            headline: "Kasse",
            yourPaymentOptions: "Deine Zahlungsmethoden",
            //healthInsuranceRefund: "Krankenkassen Rückerstattung",
            healthInsuranceRefund: "Spar dich gesund",
            checkboxHealthInsuranceRefund:
                "Ja, ich möchte vom Swica Bonusprogramm - bis zu CHF 600 Kostenrückerstattung – profitieren und kontaktiert werden. Ich bin mit der Übermittlung meiner Daten an Swica einverstanden. Weitere Informationen findest du {url}.",
            checkboxUrlText: "hier",
            footerName: "Hallo {fname}",
            footerActionText: "Nur noch ein Schritt",
        },
        checkOut: {
            headline: "Kasse",
            yourOverview: "Dein Überblick",
            yourData: "Deine Daten",
            address: "Adresse",
            yourMembershipStart: "Dein Mitgliedschaftsbeginn",
            paymentInformation: "Zahlungsinformationen",
            couponRedeem: "Einlösen",
            coupon: "Gutscheincode",
            couponAccept: "Der Gutscheincode {coupon} wurde aktiviert.",
            couponDecline: "Der Gutscheincode {coupon} wurde abgelehnt.",
            orderForFee: "Kostenpflichtig Bestellen",
            checkboxAGB: "Ich stimme den {url_agb} und den {url_dataprotection} von LakeSide Sportsclub zu.",
            checkboxURLAGB: "AGB",
            checkContract:
                "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkContract12:
                "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkHouseRules: "Hiermit akzeptiere ich die {houseRules} von LakeSide Sportsclub.",
            houseRules: "Hausordnung",
        },
    },
    fields: {
        User: "Benutzername",
        Password: "Passwort",
        fname: "Vorname",
        lname: "Nachname",
        birthday: "Geburtstag",
        phone: "Telefon",
        email: "E-Mail",
        street: "Straße",
        streetNo: "Hausnummer",
        zipcode: "PLZ",
        city: "Stadt",
    },
    validations: {
        messages: {
            alpha: "{_field_} darf nur alphabetische Zeichen enthalten",
            alpha_dash: "{_field_} darf alphanumerische Zeichen sowie Striche und Unterstriche enthalten",
            alpha_num: "{_field_} darf nur alphanumerische Zeichen enthalten",
            alpha_spaces: "{_field_} darf nur alphanumerische Zeichen und Leerzeichen enthalten",
            between: "{_field_} muss zwischen {min} und {max} liegen",
            confirmed: "Die Bestätigung von {_field_} stimmt nicht überein",
            digits: "{_field_} muss numerisch sein und exakt {length} Ziffern enthalten",
            dimensions: "{_field_} muss {width} x {height} Bildpunkte groß sein",
            email: "{_field_} muss eine gültige E-Mail-Adresse sein",
            excluded: "{_field_} muss ein gültiger Wert sein",
            ext: "{_field_} muss eine gültige Datei sein",
            image: "{_field_} muss eine Grafik sein",
            oneOf: "{_field_} muss ein gültiger Wert sein",
            integer: "{_field_} muss eine ganze Zahl sein",
            length: "Die Länge von {_field_} muss {length} sein",
            max: "{_field_} darf nicht länger als {length} Zeichen sein",
            max_value: "{_field_} darf maximal {max} sein",
            mimes: "{_field_} muss einen gültigen Dateityp haben",
            min: "{_field_} muss mindestens {length} Zeichen lang sein",
            min_value: "{_field_} muss mindestens {min} sein",
            numeric: "{_field_} darf nur numerische Zeichen enthalten",
            regex: "Das Format von {_field_} ist ungültig",
            required: "{_field_} ist ein Pflichtfeld",
            required_if: "{_field_} ist ein Pflichtfeld",
            size: "{_field_} muss kleiner als {size}KB sein",
            double: "Das Feld {_field_} muss eine gültige Dezimalzahl sein",
        },
    },
};
