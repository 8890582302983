<template>
  <div class="vCheckout1">
    <steps :steps="[]" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="hr mx-0"><span>Deine Daten</span></div>

          <div class="row my-3">
            <div class="col">
              <label class="radio">
                  <input type="radio" name="gender" v-model="gender" value="F"><span></span> {{ $t('widget.user.genderFemale') }}
              </label>
              <label class="radio">
                <input type="radio" name="gender" v-model="gender" value="M"><span></span> {{ $t('widget.user.genderMale') }}
              </label>
              <label class="radio">
                <input type="radio" name="gender" v-model="gender" value="D"><span></span> {{ $t('widget.user.genderDivers') }}
              </label>
            </div>
          </div>

          <div class="row my-2">
              <div class="col-md-6 pr-md-1">
                <div class="form-group">
                  <ValidationProvider name="fname" rules="required" v-slot="v">
                  <input class="form-control" type="text" v-model="$store.state.user.fName" :class="{filled:fName}" maxlength="50" required />
                  <label>{{ $t('widget.user.firstname') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-md-6 pl-md-1">
                <div class="form-group">
                  <ValidationProvider name="lname" rules="required" v-slot="v">
                  <input class="form-control" type="text" v-model="lName" :class="{filled:lName}" maxlength="50" required />
                  <label>{{ $t('widget.user.lastname') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
          </div>
          <div class="row my-2">
            <div class="col">
              <div class="form-group">
                <ValidationProvider name="email" rules="required|email" v-slot="v">
                  <input class="form-control" type="email" v-model="email" maxlength="50" :class="{filled:email}" required >
                  <label>{{ $t('widget.user.email') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="row my-2">
            <div class="col-md-8 pr-md-1">
              <div class="form-group">
                <ValidationProvider name="phone" rules="required" v-slot="v">
                  <VuePhoneNumberInput default-country-code="CH" :noFlags="false" :noExample="true" :noCountrySelector="false" :only-countries="['CH', 'DE', 'FR', 'AT', 'IT']" :translations="translation" :placeholder="0" maxlength="12" v-model="phone" :class="{filled:phone}"  />
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-md-4 pl-md-1">
              <div class="form-group">
                <ValidationProvider name="birthday" rules="required" v-slot="v">
                  <date-picker v-model="birthday" @dp-change="birthdayChanged" :config="dateOptionsBirthday" :class="{filled:birthday}" :disabled="false" required></date-picker>
                  <label>{{ $t('widget.user.birthday') }}</label>
                  <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>


          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0"><span>{{  $t('pages.selectAddress.yourAddress') }}</span></div>

            <div class="row my-1">
              <div class="col-md-9 pr-md-1">
                <div class="form-group">
                  <ValidationProvider name="street" rules="required" v-slot="v">
                    <input class="form-control" type="text" v-model="street" :class="{filled:street}" maxlength="60" required>
                    <label>{{  $t('widget.address.street') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col pl-md-1">
                <div class="form-group">
                  <ValidationProvider name="streetNo" rules="required" v-slot="v">
                    <input class="form-control" type="text" v-model="streetNo" :class="{filled:streetNo}" maxlength="15" required>
                    <label>{{  $t('widget.address.streetno') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row my-1">
              <div class="col-md-4 pr-md-1">
                <div class="form-group">
                  <ValidationProvider name="zipcode" rules="required" v-slot="v">
                    <input class="form-control" type="text" v-model="zipcode" maxlength="4" :class="{filled:zipcode}" required>
                    <label>{{  $t('widget.address.zipcode') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col pl-md-1">
                <div class="form-group">
                  <ValidationProvider name="city" rules="required" v-slot="v">
                    <input class="form-control" type="text" v-model="city" :class="{filled:city}" maxlength="30" required>
                    <label>{{  $t('widget.address.city') }}</label>
                    <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="col-12">
          <div class="d-flex flex-column">
            <div class="hr mx-0"><span>{{  $t('pages.selectAddress.yourMembership') }}</span></div>
            <div class="row my-1">
              <div class="col">
                <div class="form-group">
                  <date-picker v-model="membership" :config="dateOptionsMembership" class="active" :disabled="$store.state.order.membership.disabled" style="opacity: 0"></date-picker>
                  <label>{{  $t('widget.membership.startOfMembership') }}*</label>
                  <div class="form-control"><strong>{{ membership }}</strong> ({{$t('widget.membership.trainingsStart', {date:getTrainingsStart()})}})</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isMinior()">
            <div class="hr mx-0"><span>{{  $t('widget.miniors.headline') }}</span></div>
            <div class="row my-1">
              <div class="col">
                <ol class="minors">
                  <li>{{ $t('widget.miniors.minors1') }}</li>
                  <li>
                    <i18n path="widget.miniors.minors2" tag="span">
                      <template v-slot:Formular>
                        <a :href="$t('widget.miniors.formUrl')" target="_blank">{{ $t('widget.miniors.form') }}</a>
                      </template>
                    </i18n>
                  </li>
                  <li>{{ $t('widget.miniors.minors3') }}</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="h-100 d-flex flex-column">
            <div class="row my-2">
              <div class="col">
                <label class="checkbox">
                    <input type="checkbox" name="check1" v-model="check1" value="F"><i></i>
                    <i18n path="pages.selectData.checkboxDataPolice" tag="span">
                      <template v-slot:DP_URL>
                        <a :href="$root.config.urlPrivacy" target="_blank">{{ $t('pages.selectData.dataProtection') }}</a>
                      </template>
                    </i18n>
                </label>
              </div>
            </div>

            <!--<div class="row my-2">
              <div class="col">
                <label class="checkbox" >
                  <input type="checkbox" name="gender" v-model="check2" value="F"><i></i>
                  {{ $t('pages.selectData.checkboxNewsletter') }}
                </label>
              </div>
            </div>-->
            <div class="row d-none d-sm-block">
                <div class="col mt-3">
                    <button class="btn btn-primary btn-block btn-xxl" @click="next" :disabled="invalid || !check1">
                      {{  $t('common.next') }}</button>
                </div>
            </div>
          </div>
        </div>
      </div>
      <footer-link-bar></footer-link-bar>
    </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import moment from "moment";

import Steps from "@/components/Steps";
import { extend } from 'vee-validate';
import { required, email } from "vee-validate/dist/rules";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import FooterLinkBar from "@/components/FooterLinkBar";
import {mapState} from "vuex";

extend('email', email);
extend('required', required);

export default {
  name: "Checkout1.vue",
  components: {Steps, VuePhoneNumberInput, FooterLinkBar},
  computed:{
    ...mapState(["contract", "order"]),
    street:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ street: nValue });},
      get(){return this.$store.state.user.street;}
    },
    streetNo:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ streetNo: nValue });},
      get(){return this.$store.state.user.streetNo;}
    },
    zipcode:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ zipcode: nValue });},
      get(){return this.$store.state.user.zipcode;}
    },
    city:{
      set(nValue, oValue){this.$store.commit("SET_USER_DATA",{ city: nValue });},
      get(){return this.$store.state.user.city;}
    },
    membership:{
      set(nValue, oValue){this.$store.commit("SET_MEMBERSHIP",nValue);},
      get(){return this.$store.state.order.membership.value;}
    },
    dateOptionsMembership(){
      let options = {
        format: this.$store.state.order.membership.format,
        minDate: this.$store.state.order.membership.minDate,
        maxDate: this.$store.state.order.membership.maxDate,
        useCurrent: false,
        locale: 'de-de',
      }

      switch(this.$i18n.locale){
        case 'fr':
          options.locale = "fr-fr";
          break;
        case 'it':
          options.locale = "it-it";
          break;
        case 'en':
          options.locale = "en-gb";
          break;
        default:
          options.locale = "de-de";
      }


      options.locale = this.$i18n.locale;

      /*if(this.contract.vertLaufzeit > 3){
          options.format = '01.MM.YYYY';
          options.minDate = '2021-04-01';
      }*/

      return options;
    },
      fName:{
        set(nValue, oValue){
            this.$store.commit("SET_USER_DATA",{ fName: nValue });
        },
        get(){
            return this.$store.state.user.fName;
        }
      },
      lName:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ lName: nValue });
        },
        get(){
          return this.$store.state.user.lName;
        }
      },
      email:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ email: nValue });
        },
        get(){
          return this.$store.state.user.email;
        }
      },
      phone:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ phone: nValue });
        },
        get(){
          return this.$store.state.user.phone;
        }
      },
      birthday:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ birthday: nValue });
        },
        get(){
          return this.$store.state.user.birthday;
        }
      },
      gender:{
        set(nValue, oValue){
          this.$store.commit("SET_USER_DATA",{ gender: nValue });
        },
        get(){
            return this.$store.state.user.gender;
        }
      }
  },
  mounted(){
    let max = new Date();
    max.setFullYear(max.getFullYear()-16);
    this.dateOptionsBirthday.maxDate = max;

    let min = new Date();

    min.setFullYear(min.getFullYear()-115);
    this.dateOptionsBirthday.minDate = min;

    this.$store.commit("SET_STEP", {step:4});

  },
  methods: {
      next(){
        this.$store.dispatch('setCustomer', {data: this.$store.state.user, membership: this.$store.state.order.membership.value});

        if(this.recommendations){
          this.$store.dispatch('setRecommendations', {recommendations: this.recommendations});
        }

        this.$store.commit('SET_STEP', {step: 7});
      },
      isMinior(){
          return false;
          /*
          if(!this.birthday) return false;

          let today = moment();
          let birthday = moment(this.birthday, "DD.MM.YYYY");
          let years = moment.duration(today.diff(birthday)).asYears();

          //alert(years);

          return years < 18;*/
      },
      birthdayChanged(v1, v2){
        console.log(v1, v2);
      },
      check(){
        if(!this.fName){
            return true;
        }

        if(!this.lName){
          return true;
        }

        if(!this.email){
          return true;
        }

        if(!this.check1){
          return true;
        }

        return false;
      },
    getTrainingsStart(){
      if(this.$store.state.order.membership.format == "DD.MM.YYYY"){
        return this.$store.state.order.membership.value;
      }

      if(this.$store.state.order.membership.value > this.$store.state.order.membership.defaultValue){
        return this.$store.state.order.membership.value;
      }

      return this.$store.state.order.accessdate.value;




      /*
      if(this.$store.state.studio.id != 25) {
        return this.membership;
      }

      if(this.membership > this.$store.state.order.membership.defaultValue){
        return this.membership;
      }
      return this.$t('widget.membership.rightAway');*/
    }
  },
  data() {
    return {
      coreHover: false,
      premiumHover: false,
      recommendations:false,
      translation: { countrySelectorLabel: 'Ländercode', countrySelectorError: 'Choose country 1', phoneNumberLabel: 'Telefonnummer', example: 'Bsp:' },
      check1: false,
      check2: false,
      dataProtectionURL: "https://basefit.ch",
      dateOptionsBirthday: {
        format: 'DD.MM.YYYY',
        maxDate: null,
        useCurrent: false,
        locale: 'de-de',
        viewMode: 'decades',
        debug: false,
      }
    };
  }
}
</script>

<style scoped>

</style>