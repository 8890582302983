<template>
  <div>
    <div id="payment-page">
      <div class="container">
        <div class="payment-container">
          <div id="dropin-container" :ref="`${type}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdyenCheckout from '@adyen/adyen-web';
//import '@adyen/adyen-web/dist/adyen.css';

let reference = null;

// Event handlers called when the shopper selects the pay button,
// or when additional information is required to complete the payment
async function handleSubmission(state, component, url) {
  try {
    const res = await callServer(url, state.data);
    handleServerResponse(res, component);
  } catch (error) {
    console.error(error);
    alert("Error occurred. Look at console for details");
  }
}
// Calls your server endpoints
async function callServer(url, data) {

  url = "https://lakeside.dev.bitdivision.de" + url;

  const res = await fetch(url, {
    method: "POST",
    body: data ? JSON.stringify(data) : "",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return await res.json();
}
// Handles responses sent from your server to the client
function handleServerResponse(res, component) {
  if (res.action) {
    component.handleAction(res.action);
  } else {
    alert(res.resultCode);

    switch (res.resultCode) {
      case "Authorised":
        reference.$router.push('thankyou');
        break;
      case "Pending":
      case "Received":
        reference.$router.push('thankyou');
        break;
      case "Refused":
        reference.$router.push('thankyou');
        break;
      default:
        reference.$router.push('thankyou');
        break;
    }
  }
}
function filterUnimplemented(pm) {
  pm.paymentMethods = pm.paymentMethods.filter((it) =>
      [
        "scheme",
        "ideal",
        "dotpay",
        "giropay",
        "sepadirectdebit",
        "directEbanking",
        "ach",
        "alipay",
        "klarna_paynow",
        "klarna",
        "klarna_account",
        "boletobancario_santander",
      ].includes(it.type)
  );
  return pm;
}
async function initCheckout(ref) {
  try {
    const { response, clientKey } = await callServer("/api/getPaymentMethods");

    const configuration = {
      paymentMethodsResponse: filterUnimplemented(response),
      clientKey,
      locale: "de_DE",
      environment: "test",
      showPayButton: true,
      paymentMethodsConfiguration: {
        ideal: {
          showImage: true,
        },
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          name: 'Kredit- oder Debitkarte',
          amount: {
            //value: 1000,
            currency: "EUR",
          },
        },
      },
      onSubmit: (state, component) => {
        if (state.isValid) {
          handleSubmission(state, component, "/api/initiatePayment");
        }
      },
      onAdditionalDetails: (state, component) => {
        handleSubmission(state, component, "/api/submitAdditionalDetails");
      },
    };
    const checkout = new AdyenCheckout(configuration);
    //const integration = checkout.create(ref.type).mount(ref.$refs[ref.type]);

    const dropin = checkout
        .create('dropin', {
          // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
          openFirstPaymentMethod:false
        })
        .mount('#dropin-container');

  } catch (error) {
    console.error(error);
    alert("Error occurred. Look at console for details");
  }
}
export default {
  components: {},
  head() {
    return {
      title: "Payment page",
    };
  },
  asyncData({ route }) {
    return { type: route.params.payment };
  },
  mounted() {
    reference = this;
    initCheckout(this);

  },
};
</script>