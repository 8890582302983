export default {
    wohlen: "Cours disponibles à partir du 5 juillet",
    common:{
        dateFormat: "DD.MM.YYYY",
        currency:  "CHF {value}",
        choose:     "Choisir",
        next:       "Continuer",
        previous:   "Retour",
        bestChoice: "Le meilleur choix",
        and:        "et",
        or:         "ou",
        edit:       "Modifier",
        basket:     "Panier",
        yourStudio: "Votre club",
        yourPlan:   "Votre abonnement",
        yourAddition: "Vos extras",
        noAddition: "Continuer sans extra",
        days : "Jour | 1 jour | {count} jours",
        months : "Mois | 1 mois | {count} mois",
        checkout: "À la caisse",
    },
    widget:{
        miniors: {
            headline: "Mineurs",
            minors1: "Vous n'êtes pas encore majeure?",
            minors2: "Demandez à votre parent ou tuteur de remplir ce {Formulaire}",
            form: "Formulaire",
            formUrl: "https://basefit.ch/wp-content/uploads/2021/04/FR_U18_Formular_Abo.pdf\n",
            minors3: "Apportez votre carte d'identité et votre tuteur à votre 1er entraînement et c'est parti!",
        },
        studio:{
          searchPlaceholder : "Où voulez-vous vous entraîner?",
          clubList : "Liste des clubs",
          mapView : "Vue de la carte"
        },
        basket:{
            totalAmountFull: "MONTANT TOTAL",
            totalAmount: "Total",
        },
        user:{
          genderFemale : "Mme.",
          genderMale : "Homme",
          genderDivers : "Divers",
          firstname : "Prénom",
          lastname : "Nom de famille",
          email : "E-mail",
          phone : "Téléphoner",
          birthday : "Anniversaire.",
        },
        address:{
          street : "Rue",
          streetno : "Numéro de maison",
          zipcode : "NPA",
          city : "Ville",
          country : "Pays",
        },
        membership:{
          membership : "Adhésion",
          startOfMembership : "Votre adhésion commence",
          trainingsStart : "Début formation {date} ",
          rightAway : "immédiatement",
        }
    },
    footer:{
          backToHomepage : "Retour sur le site",
          imprint : "Mentions légales",
          dataProtection : "Vie privée",
          paymentOptions : "Paiement",
        },
    steps: {
          step1_1 : 'Choisir club',
          step1_2 : "Choisir l'adhésion",
          step1_3 : 'Ajouts',
          step1_4 : 'Résumé',
          step2_1 : 'Données',
          step2_2 : 'Adresse',
          step2_3 : "Paiement",
          step2_4 : "Confirmation",
    },
    pages:{
        selectClub:{
            headline: "Choisissez un club",
        },
        selectContract: {
            headline: "Choisissez votre adhésion",
            choosePlan: "Choisissez votre adhésion",
            chooseRuntime: "Choisissez votre terme",
            basisPlan : "Offre de base",
            aktionPlan: "Offre spéciale",
            premiumPlan : "Meilleure offre",
            planPoints: [
                    "App Basefit.ch (utilisation en temps réel)",
                    "Fitness On-Demand",
                    "Accès à tous les clubs",
                    "Un ami s'entraîne gratuitement le week-end",
                    "Période d'annulation",
                    "Mettez votre adhésion en pause",
                    "Frais d'inscription",
                    "Période d'annulation",
            ],
            tooltips:{
                weekendFriend: "Les membres Premium ont la possibilité d'amener une personne supplémentaire (\"ami\") avec eux pour partager l'utilisation des installations le week-end (samedi et dimanche) sans frais supplémentaires. Voir les conditions générales p.6 pour plus de détails.",
                freezeMembership: "A justified ´time-stop´ of up to 9 months (for example military service, illness, professional stay abroad) and unfounded ´time-stop´ of up to 3 months per year are permitted. See terms and conditions p.13 for further details.",
            }
        },
        selectAddition:{
            headline: "Rendre votre abonnement complèt",
            unselectAddition: "Continuer sans extra",
        },
        overview:{
            headline: "Votre résumé",
            yourData: "Vos données",
            voucherHintHeadline:  "Avez-vous un coupon?",
            voucherHint:  "Vous pouvez l’utiliser à l’étape suivante.",
        },
        selectData:{
            headline: "Checkout",
            dataProtection: "Protection des données",
                checkboxDataPolice: "J'accepte les {DP_URL} de basefit.ch.",
                checkboxNewsletter: "Oui, je souhaite des infos publicitaires et des informations sur d'autres produits basefit.ch et ses partenaires.",
                footerActionText: "Veuillez remplir",
                yourData: "Vos données",
            },
        selectAddress:{
            headline: "Checkout",
            yourAddress: "Votre Adresse",
                yourMembership: "Adhésion",
                howDoYouKnow: "Comment vous nous connaissez?",
                footerName: "Bonjour {fname}",
                footerActionText: "Veuillez entrer votre adresse",
            },
            selectPayment:{
                headline: "Checkout",
                yourPaymentOptions: "Vos modes de paiement",
                //healthInsuranceRefund: "Remboursement de l'assurance",
                healthInsuranceRefund: "Préservez votre santé",
                checkboxHealthInsuranceRefund: "Oui, je souhaite bénéficier du programme de bonus Swica - jusqu'à 600 CHF de remboursement de frais - et être contacté. J'accepte le transfert de mes données à Swica. Pour plus d'informations, veuillez consulter {url}.",
                checkboxUrlText: "ici",
                footerName: "Hallo {fname}",
                footerActionText: "Nur noch ein Schritt",
            },
        checkOut:{
            headline: "Checkout",
            yourOverview: "Votre aperçu",
            yourData: "Votre détails",
            address: "Adresse",
            yourMembershipStart : "Votre adhésion commence",
            paymentInformation : "Paiement",
            couponRedeem:  "Racheter",
            coupon:    "Code promo",
            couponAccept: "Le code du bon {coupon} est accepté.",
            couponDecline: "Le code de {coupon} a été refusé.",
            orderForFee : "Acheter maintenant",
            checkboxAGB: "J'accepte les {url_agb} la {url_dataprotection} de basefit.ch.",
            checkboxURLAGB: "conditions générales et",
            checkContract: "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkContract12: "Ich stimme zu, dass der Vertrag sich jeweils um weitere 1 Monat zu den allgemeinen Bedingungen verlängert, wenn er nicht mindestens 10 Werktage vor Ablauf durch schriftliche Kündigung aufgehoben wird.",
            checkHouseRules: "J'accepte le {houseRules} de basefit.ch.",
            houseRules: "règlement intérieur",
        },
    },
    fields:{
        fname : "Prénom",
        lname : "Nom de famille",
        birthday: "Anniversaire",
        phone: "Téléphoner",
        email: "E-mail",
        street: "Rue",
        streetNo: "Numéro",
        zipcode: "NPA",
        city: "Ville",
    },
    validations: {
        "messages": {
            "alpha": "Le champ {_field_} ne peut contenir que des lettres",
            "alpha_num": "Le champ {_field_} ne peut contenir que des caractères alpha-numériques",
            "alpha_dash": "Le champ {_field_} ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
            "alpha_spaces": "Le champ {_field_} ne peut contenir que des lettres ou des espaces",
            "between": "Le champ {_field_} doit être compris entre {min} et {max}",
            "confirmed": "Le champ {_field_} ne correspond pas",
            "digits": "Le champ {_field_} doit être un nombre entier de {length} chiffres",
            "dimensions": "Le champ {_field_} doit avoir une taille de {width} pixels par {height} pixels",
            "email": "Le champ {_field_} doit être une adresse e-mail valide",
            "excluded": "Le champ {_field_} doit être une valeur valide",
            "ext": "Le champ {_field_} doit être un fichier valide",
            "image": "Le champ {_field_} doit être une image",
            "integer": "Le champ {_field_} doit être un entier",
            "length": "Le champ {_field_} doit contenir {length} caractères",
            "max_value": "Le champ {_field_} doit avoir une valeur de {max} ou moins",
            "max": "Le champ {_field_} ne peut pas contenir plus de {length} caractères",
            "mimes": "Le champ {_field_} doit avoir un type MIME valide",
            "min_value": "Le champ {_field_} doit avoir une valeur de {min} ou plus",
            "min": "Le champ {_field_} doit contenir au minimum {length} caractères",
            "numeric": "Le champ {_field_} ne peut contenir que des chiffres",
            "oneOf": "Le champ {_field_} doit être une valeur valide",
            "regex": "Le champ {_field_} est invalide",
            "required": "Le champ {_field_} est obligatoire",
            "required_if": "Le champ {_field_} est obligatoire lorsque {target} possède cette valeur",
            "size": "Le champ {_field_} doit avoir un poids inférieur à {size}KB",
            "double": "Le champ {_field_} doit être une décimale valide"
        }
    },
    promo: {
        desktop: "Bénéficiez de 50% de réduction sur votre Base1 Core Abo et sans frais d´inscription: Utilisez le code promotionnel <b>BASEFIT50</b> à la dernière étape de la boutique en ligne.",
        mobile: "Utilisez le code promotionnel <b>BASEFIT50</b> pour bénéficier de -50% sur votre Base1 Core Abo",
    }
};
