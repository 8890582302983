<template>
  <div class="basket flex-grow-1 mb-2 ">
    <div class="h-100 row m-0 align-items-center">
      <ul class="col">
        <li v-for="item in basket.items" :key="item">
          {{item.name}}
          <span class="price">{{item.value | formatCurrency5}}</span>
        </li>
        <li class="line"></li>
        <li>
          <strong>{{ $t('widget.basket.totalAmount')}}</strong>
          <span class="price">{{basket.total | formatCurrency5}}</span>
        </li>
        <li class="mt-3" v-if="basket.realprice">
          Preis nach Ablauf der 14-tätigen Testphase: <span class="highlight"><strong>{{basket.realprice | formatCurrency4}}</strong></span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Basket",
  computed: mapState(["basket"]),
}
</script>

<style scoped>

</style>