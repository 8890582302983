import Vue from "vue";
import helper from "@/facebook";

const SET_STUDIO = (state, { studio }) => {
    //helper.fbq('track', 'Lead');
    state.studio = studio;
};
const SET_CONTRACT  = (state, { contract }) => {

    //helper.fbq('track', 'Lead');
    //helper.fbq('track', 'AddToCart', { currency: 'CHF',});

    state.contract = contract;
};
const SET_ADDITION = (state, { addition }) => {
    //helper.fbq('track', 'Lead');
    //helper.fbq('track', 'AddToCart', { currency: 'CHF',});
    state.addition = addition;
};
const NEXT_STEP  = (state) => {
    state.currentStep++;
};
const SET_STEP  = (state, { step }) => {
    if(step === undefined) step = 0;
    state.currentStep = step;
};
const SET_BACKOFFICE  = (state, { basket, order }) => {
    state.basket = basket;

    Object.keys(order).forEach( key => {
        Vue.set(state.order, key,order[key])
    });

    //state.order = order;
    // localStorage.setItem("currentStep", state.currentStep);
};

const SET_PAYMENT = (state, data) => {
    helper.fbq('track', 'AddPaymentInfo');
    state.order.payment = data;
}
const SET_QUALITOP = (state, data) => {
    state.order.qualitop = data;
}
const SET_ORDER_DATA = (state, data) => {
    Object.keys(data).forEach( key => {
        Vue.set(state.order, key, data[key])
    });
};
const SET_USER_DATA = (state, data) => {
    Object.keys(data).forEach( key => {
      Vue.set(state.user, key, data[key])
    });
};
const SET_MEMBERSHIP = (state, data) => {
    state.order.membership.value = data;
};
const SET_LOCALE = (state, data) => {
    state.locale = data;
};

const SET_RECOMMENDATIONS = (state, data) => {
    state.recommendations = data.data;
};

const reset = (state, data) => {
    state.user = {
            gender: 'F',
                fName: "",
                lName: "",
                email: "",
                phone: "",
                birthday: "",
                street: "",
                streetNo: "",
                zipcode: "",
                city: "",
        };
    state.recommendations = false;
    state.contract = null;
    //state.studio = null;
    state.currentStep = 0;
    state.addition = null;
    state.order = {
            payment: null,
            qualitop: null,
            membership: {
                format: "DD.MM.YYYY",
                minDate: new Date(),
                value: new Date(),
                defaultValue: new Date(),
                disabled: true,
            },
            accessdate: {
                format: "DD.MM.YYYY",
                minDate: new Date(),
                value: new Date(),
                defaultValue: new Date(),
                disabled: true,
            }
        };
    state.basket =  {};
}


export default {
    reset,
    SET_LOCALE,
    SET_MEMBERSHIP,
    SET_USER_DATA,
    SET_PAYMENT,
    SET_QUALITOP,
    SET_STUDIO,
    SET_CONTRACT,
    SET_ADDITION,
    NEXT_STEP,
    SET_STEP,
    SET_BACKOFFICE,
    SET_RECOMMENDATIONS,
};