<template>
  <div class="steps">
    <ul>
      <li :class="{active: currentStep == 0, finish: currentStep > 0}">
        <span @click="goto(0)">{{ step1 }}</span>
      </li>
      <li :class="{active: currentStep == 1, finish: currentStep > 1}">
        <span @click="goto(1)">{{ step2 }}</span>
      </li>
      <li :class="{active: currentStep == 2, finish: currentStep > 2}">
        <span @click="goto(2)">{{ step3 }}</span>
      </li>
      <li :class="{active: currentStep == 3}">
        <span>{{ $t('steps.step1_4') }}</span>
      </li>
      <!--<li :class="{active: step.active, finish: step.finish}" v-for="(step, theKey, index) in steps" :key="index">
        <span v-if="step.finish" @click="$store.commit('SET_STEP', {step: step.step})" :id="theKey.toString()">{{ getTitle(step) }}</span>
        <span v-else :id="theKey.toString()">{{ getTitle(step) }}</span>
      </li>-->
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "StepsPart1",
  props: ['steps'],
  computed:{
    ...mapState(["studio", "contract", "addition"]),
    currentStep(){
        return this.$store.state.currentStep;
    },
    step1(){
      if(this.studio != undefined && this.studio){
        return this.studio.name;
      }

      return this.$t('steps.step1_1');
    },
    step2(){
      if(this.contract != undefined && this.contract){
        return this.contract.Bezeichnung;
      }

      return this.$t('steps.step1_2');
    },
    step3(){
      if(this.addition != undefined && this.addition){
        return this.addition.bezeichnung_current_language;
      }

      return this.$t('steps.step1_3');
    },
  },
  watch:{
      currentStep(){

      },
  },
  methods: {
      goto(index){
          if(this.currentStep <= index) return false;

          this.$store.commit("SET_STEP", {step: index});
      },
      getTitle(step){
          if(step.value){
            return step.value;
          }
          return step.name;
      }
  }
}
</script>

<style scoped>

</style>