<template>
  <div class="vCheckout3">

    <h1 class="text-center">{{ $t('pages.selectPayment.headline') }}</h1>
    <steps :steps="[]" />

    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0"><span>{{ $t('pages.selectPayment.yourPaymentOptions') }}</span></div>

            <ul class="row payment">
              <li class="col-md-6"  v-for="pay in pms" :key="pay.id">
                <div  @click="selectPayment(pay.id)" :class="{selected: payment && pay.id == payment}" >
                  <img class="icon" :src="pay.icon" />
                  {{pay.name}}
                </div>
              </li>
            </ul>

            <div class="hr mx-0"><span>{{ $t('pages.selectPayment.healthInsuranceRefund') }}</span></div>

            <div class="row my-2">
              <div class="col">
                <label class="checkbox">
                  <input type="checkbox" name="HealthInsuranceRefund" v-model="qualitop" value="F"><i></i>
                  <i18n path="pages.selectPayment.checkboxHealthInsuranceRefund" tag="span">
                    <template v-slot:url>
                      <a :href="HealthInsuranceRefundURL" target="_blank">{{ $t('pages.selectPayment.checkboxUrlText') }}</a>
                    </template>
                  </i18n>
                  <div>
                  <img src="/shop/img/Qualitop-Logo.png" class="mt-3" style="">
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="col d-none d-sm-block">
          <div class="">
            <div class="hr mx-0"><span>{{ $t('common.basket') }}</span></div>
            <Basket></Basket>
            <div class="row">
              <div class="col">
                <button class="btn btn-primary btn-block btn-xxl" @click="$store.commit('NEXT_STEP')" :disabled="check()">{{ $t('common.next') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-link-bar></footer-link-bar>
    <Footer :button-only-mobile="true"  @clickNext="$store.commit('NEXT_STEP')" :button-enabled="!check()" :headline="$t('pages.selectPayment.footerName', {fname: $store.state.user.fName})" :subheadline="$t('pages.selectPayment.footerActionText')"></Footer>
  </div>
</template>

<script>

import Basket from "@/components/Basket";
import Steps from "@/components/StepsPart2";
import Footer from "@/components/Footer";
import FooterLinkBar from "@/components/FooterLinkBar";

export default {
  name: "Checkout3",
  components: {Basket, Steps, Footer, FooterLinkBar},
  computed:{
      pms(){
        Object.filter = (obj, predicate) =>
            Object.keys(obj)
                .filter( key => predicate(obj[key]) )
                .reduce( (res, key) => Object.assign(res, { [key]: obj[key] }), {} );
        return Object.filter(this.paymentMethods, p => p.active);
      },
      payment:{
        get(){
          return this.$store.state.order.payment;
        },
        set(nValue, oValue){
            this.$store.commit("SET_PAYMENT", nValue);
        }
      },
      qualitop:{
        get(){
          return this.$store.state.order.qualitop;
        },
        set(nValue, oValue){
          this.$store.commit("SET_QUALITOP", nValue);
        }
      },
  },
  watch:{
      paymentMethods(){
        return this.paymentMethods;
      },
  },
  mounted() {
    this.$store.commit("SET_STEP", {step:6});

    if(this.$root.config.modus != undefined && this.$root.config.modus == "employerApp" && this.$store.state.contract.vertLaufzeit > 3){
        this.paymentMethods["ec"].active = true;
        this.paymentMethods["bar"].active = true;
    }

    if(this.$store.state.contract.vertLaufzeit <= 3){
      //this.paymentMethods["paypal"].active = false;
      this.paymentMethods["postfinance"].active = false;
    }
  },
  methods: {
    check(){
      if(!this.payment) return true;

      return false;
    },
    selectPayment(payment){
      this.payment = payment
    }
  },

  data(){
    return{
      HealthInsuranceRefundURL: "https://www.swica.ch/de/partner/fitness/basefit/swica-vorteile",
      order:{
        payment: null,
        qualitop: false,
      },
      paymentMethods: {
        "paypal": {active: true,name: "PayPal", id: "paypal", icon: "/shop/img/payments/paypal-logo.svg"},
        "visa":  {active: true,name: "Visa", id: "visa", icon: "/shop/img/payments/visa-logo.svg"},
        "mastercard":   {active: true,name: "Mastercard", id: "mastercard", icon: "/shop/img/payments/mastercard-logo.svg"},
        "postfinancecard":  {active: true,name: "Postfinancecard", id: "postfinancecard", icon: "/shop/img/payments/post-finance-logo.svg"},
        "postfinance":  {active: true, name: "Postfinance", id: "postfinance", icon: "/shop/img/payments/e-finance-logo.svg"},
        "ec":  {active: false, name: "Studio EC-Card", id: "ec", icon: "/shop/img/payments/ec-logo.svg"},
        "bar": {active: false, name: "Studio Bar-Zahlung", id: "bar", icon: "/shop/img/payments/bar-logo.svg"},
      },
      check1: false,
      check2: false,
      user: {
        fname: "",
        lname: "",
        mail: "",
        birthday: null,
        membership: "05.03.2021",
      },
      dateOptionsMembership: {
        format: 'DD.MM.YYYY',
        minDate: new Date(),
        useCurrent: false,
        locale: 'de-de',
      },
      dateOptionsBirthday: {
        format: 'DD.MM.YYYY',
        maxDate: "2015-01-01",
        useCurrent: false,
        locale: 'de-de',
      }
    }
  }
}
</script>

<style scoped>

</style>