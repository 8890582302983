<template>
  <div class="vStudio flex-grow-1" :class="{'searchFocus': searchFocus}">
    <div class="h-100 d-flex flex-column">
    <h1 class="text-center slider" :class="{'closed': searchFocus}">{{ $t('pages.selectClub.headline') }}</h1>
  <steps :steps="[]"  class="slider" :class="{'closed': searchFocus}" />

  <div class="container-fluid container-md flex-grow-1" :class="{'pt-3': searchFocus}" >
      <div class="row h-100 viewContainer" :class="{map: mapView}">
        <div id="list" class="col pr pr-sm-1" style="min-height: 60vh;">
          <div class="h-100  d-flex flex-column">
              <div class="row justify-content-center">
                <div class="col">
                  <div class="form-group mb-0">
                    <input type="text" class="form-control" @focusin="searchFocus=true"  :class="{filled: searchTerm}" v-model="searchTerm" >
                    <label> {{ $t('widget.studio.searchPlaceholder') }}</label>
                  </div>
                  <div class="row d-sm-none">
                    <div class="col text-right mt-1">
                      <button @click="map()" class="btn btn-primary btn-sm"><i class="fas fa-map-marked-alt"></i> {{  $t('widget.studio.mapView') }}</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="hr mx-0"><span>{{ $t('widget.studio.clubList') }}</span></div>
                </div>
              </div>

            <studio-list class="flex-grow-1"  @clicked="onStudioChanged" :studios="studios" :selected-studio="studio"></studio-list>

            <div  class="row footer" >
              <div class="col">
                <a :href="$root.config.urlHome"><font-awesome-icon icon="arrow-left" /> {{ $t('footer.backToHomepage') }}</a>
              </div>
              <div class="col-auto text-right">
                <a :href="$root.config.urlPrivacy">{{ $t('footer.dataProtection') }}</a>
              </div>
              <div class="col-auto text-right">
                <a :href="$root.config.urlImprint">{{ $t('footer.imprint') }}</a>
              </div>
            </div>
          </div>
        </div>
        <div id="mapview" class="col px-0 pl-sm-3 d-none d-sm-block" style="min-height: 60vh;">
          <div class="row h-100">
          <div class="row px-3 d-sm-none">
            <div class="col ">
                <a href="#" class="btn btn-sm" @click="mapView = false"><i class="fas fa-arrow-left"></i> {{ $t('common.previous') }}</a>
            </div>
          </div>
          <GmapMap
              :center="center"
              :zoom="9"
              :options="maps"
              style="width: 100%; height: 100%;"
          >
            <gmap-cluster
                :gridSize="30"
                :zoomOnClick="true"
                :enableRetinaIcons="true"
                :minimumClusterSize="5"
                ref="cluster">
            <GmapMarker
                :key="index"
                v-for="(m, index) in studios"
                :position="getMarkerPosition(m)"
                :clickable="true"
                :draggable="false"
                :icon="getMarkerIcon(m)"
                @click="toggleInfoWindow(m, index)"
            />
            </gmap-cluster>
            <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen=false"
            >
              <div class="container-fluid infobox" v-if="selectedStudio">
                <div class="title">
                  {{selectedStudio.name}}
                </div>
                <hr>
                <address>
                  {{selectedStudio.addressStreet}}<br>
                  {{selectedStudio.addressZipcode}} {{selectedStudio.addressCity}}
                </address>
                <div class="text-right">
                  <strong>Tel:</strong>
                  {{selectedStudio.addressPhone}}
                </div>
                <div class="text-right">
                  <strong>Mail:</strong>
                  {{selectedStudio.addressMail}}
                </div>
                <a href="#" class="btn btn-primary btn-block" @click="selectStudio(selectedStudio)">Wählen</a>
              </div>
              <div v-html="infoContent"></div>
            </gmap-info-window>

          </GmapMap>
        </div>
      </div>
  </div>
    </div>
  </div>
  </div>
</template>

<script>
import $ from "jquery/dist/jquery.min"
import StudioList from "@/components/StudioList";
import Steps from "@/components/Steps";
import {mapState} from "vuex";
import helper from "@/facebook";



export default {
  name: "Studios",
  components: {StudioList, Steps},
  computed: {
    ...mapState(["studio", "contract"]),
  },
  data(){
    return{
      searchFocus: false,
      mapView: false,
      searchTerm: "",
      studios: [],
      infoContent: '',
      infoOptions: {
        minWidth: 230,
        pixelOffset: {
          width: 0,
          height: 230
        }
      },
      infoWindowPos: {
        lat: 0,
        lng: 0
      },
      infoWinOpen: false,
      maps: {
        mapTypeControl: false,
        fullscreenControl: true,
        streetViewControl: false,
        styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#777777"}]}, {"featureType": "administrative.province","elementType": "all","stylers": [{"visibility": "off"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f7f7f7"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#e1e1e1"},{"visibility":"on"}]}]
      },
      center:{lat:46.856570, lng:8.218383}
    }
  },
  watch:{
    searchTerm(){
      this.loadStudios();
    }
  },

  mounted(){
    if(this.$root.config.modus != undefined && this.$root.config.modus == "employerApp"){
      this.$router.push('login');
      return;
    }

    this.$store.commit("SET_STEP", {step: 1});
    return;

    /*
    this.loadStudios();
    this.$store.commit("SET_STEP", {step: 0});*/
  },
  methods: {
    map(){
        this.mapView = true;
    },
    onStudioChanged(studio){
      helper.fbq('track', 'Lead');
      this.$store.dispatch("setStudio", {studio: studio});
      this.$emit("studioSelect", studio);
      this.$store.commit("SET_STEP", {step: 1});
    },
    loadStudios(){
      this.axios.get("/api/shop/studios",{params: {term: this.searchTerm}}).then(res => {
        this.studios = res.data.studios;
      }).catch(error => {alert("Ein Fehler ist aufgetreten"); });
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = this.getMarkerPosition(marker);
      this.infoContent = this.getInfoWindowContent(marker);
      this.selectedStudio = marker;
      this.center = this.getMarkerPosition(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }

      $('.gm-style-iw-t .gm-style-iw.gm-style-iw-c').css('padding', 0);
    },
    getMarkerPosition(marker){
      return {lat: marker.coordsLat , lng: marker.coordsLng};
    },
    getMarkerIcon(marker){

      if(marker === this.selectedStudio){
        return{
          url: "img/kreuz_small_yellow.svg",
          anchor: {x: 15, y: 15},
          scaledSize: {width:30, height:30},
        };
      }

        return  {
              url: "img/kreuz_small.svg",
              anchor: {x: 15, y: 15},
              scaledSize: {width:30, height:30},
        };
    },
    selectStudio(studio){
      helper.fbq('track', 'Lead');
      this.$store.dispatch("setStudio", {studio: studio});
      //this.center = this.getMarkerPosition(studio);
      this.$emit("studioSelect", studio);
      this.$store.commit("SET_STEP", {step: 1});
    },

    getInfoWindowContent: function (marker) {

      return (
          `
        `);
    },
  }
}

</script>

<style scoped>

</style>