<template>
  <div>
    <h1 class="text-center mb-5">Zahlung fehlgeschlagen</h1>
    <div class="container">
      <div class="row">
        <div class="col">
          <p>Leider ist deine Zahlung fehlgeschlagen oder du hast Sie abgebrochen.</p>

          <p><a href="#" @click.stop="reset()" style="font-weight: bold; text-decoration: underline;">Hier</a> kannst du eine neue Bestellung starten.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>




export default {
  name: "S3_P2_Failed",
  computed: {
  },
  data(){
    return{
      errors: false,
      user: null,
      password: null,
    }
  },
  watch:{

  },
  mounted(){
  },
  methods: {
    reset(){
      this.$store.commit('reset');
      this.$router.push('login');
    }
  }
}

</script>

<style scoped>

</style>