<template>
  <div id="app" class="h-100 d-flex flex-column">
    <header>
      <div class="container">
        <div class="row align-items-center">
            <div class="col text-center text-sm-left" id="logo_container">
              <a :href="$root.config.urlHome">
                <img src="/shop/img/Lakeside_Logo_1c.svg">
              </a>

            </div>
            <div class="col-2" v-if="false">
              <select class="form-control" v-model="$i18n.locale">
                <option v-for="lang in ['de', 'en', 'fr', 'it']" :key="lang">{{lang}}</option>
              </select>
            </div>
        </div>
      </div>


      <div class="container-fluid headerMsgContainer" v-if="false && (isClub(83) || $root.debug)">
        <div class="row" v-if="$root.debug">
          <div class="col text-center d-none d-md-block" v-html="$t('promo.desktop')"></div>
          <div class="col text-center d-md-none" v-html="$t('promo.mobile')"></div>
        </div>
        <div class="row" v-if="isClub(83)">
          <div class="col text-center">Bis Ende November 2021 geöffnet!</div>
        </div>
      </div>
    </header>

    <!--<test2></test2>-->
    <router-view></router-view>


    <hsc-window-style-metal v-if="debug" style="position: fixed; z-index: 99999">
      <hsc-window title="Debug" :resizable="true" :width="200" :height="200" >
          <div class="form-group">
              <labeL class="col">Sprache: {{$i18n.locale}}
                <select class="form-control" v-model="$i18n.locale">
                    <option v-for="lang in ['de', 'en', 'fr', 'it']" :key="lang">{{lang}}</option>
                </select>
              </labeL>
          </div>
          <div class="form-group">
            <labeL class="col">Schritt: {{currentStep}}
              <select class="form-control" v-model="currentStep">
                <option v-for="step in [1, 2, 3,4 ,5 ,6 ,7,8]" :key="step" :value="step-1" :disabled="step > 4">Schritt {{step}}</option>
              </select>
            </labeL>
          </div>

      </hsc-window>
    </hsc-window-style-metal>
    <div v-if="$root.debug" style="padding-bottom: 100px;">
    <vue-json-pretty  :data="$store.state" >
    </vue-json-pretty></div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import axios from "axios";
//import Test2 from "@/components/Test2";
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'



//adyen
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

const paymentMethodsResponse = {"paymentMethods":[{"brands":["mc","visa","amex"],"details":[{"key":"encryptedCardNumber","type":"cardToken"},{"key":"encryptedSecurityCode","type":"cardToken"},{"key":"encryptedExpiryMonth","type":"cardToken"},{"key":"encryptedExpiryYear","type":"cardToken"},{"key":"holderName","optional":true,"type":"text"}],"name":"Credit Card","type":"scheme"},{"name":"Paysafecard","type":"paysafecard"},{"name":"Pay later with Klarna.","type":"klarna"},{"name":"Pay now with Klarna.","type":"klarna_paynow"}]};

function makePayment(data){
    console.log("makePayment", data);

    this.axios.get("api/shop/order/payment").then(res =>{
      console.log(res);
    });

    return data;
}

function showFinalResult(response){
  console.log(response);
  return true;
}

const configuration = {
  paymentMethodsResponse: paymentMethodsResponse, // The `/paymentMethods` response from the server.
  clientKey: "pub.v2.8016304443352242.aHR0cDovLzE5Mi4xNjguMTc4LjExODo4MDgw.UclxYDgSD1fIlaTDw4v2GryUxhx9L4Q9Kv933l3g2_M", // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
  //clientKey: "pub.v2.8016304443352242.aHR0cHM6Ly9sYWtlc2lkZS5kZXYuYml0ZGl2aXNpb24uZGU.BinfAf-z5JrfdfPlTXE9HTcp9vdf5wXd8xH_twrYYi0", // Web Drop-in versions before 3.10.1 use originKey instead of clientKey.
  locale: "de-DE",
  environment: "test",
  onSubmit: (state, dropin) => {
    // Global configuration for onSubmit
    // Your function calling your server to make the `/payments` request
    makePayment(state.data)
        .then(response => {
          console.log("next", response);
          if (response.action) {
            // Drop-in handles the action object from the /payments response
            dropin.handleAction(response.action);
          } else {
            // Your function to show the final result to the shopper
            showFinalResult(response);
          }
        })
        .catch(error => {
          throw Error(error);
        });
  },
  onAdditionalDetails: (state, dropin) => {
    console.log("AdditionalDetails", state, dropin);
    // Your function calling your server to make a `/payments/details` request
    /*makeDetailsCall(state.data)
        .then(response => {
          if (response.action) {
            // Drop-in handles the action object from the /payments response
            dropin.handleAction(response.action);
          } else {
            // Your function to show the final result to the shopper
            showFinalResult(response);
          }
        })
        .catch(error => {
          throw Error(error);
        });*/
  },
  paymentMethodsConfiguration: {
    card: { // Example optional configuration for Cards
      hasHolderName: true,
      holderNameRequired: true,
      enableStoreDetails: true,
      hideCVC: false, // Change this to true to hide the CVC field for stored cards
      name: 'Kredit- oder Debitkarte',
      amount: {
        value: 1000,
        currency: "EUR",
      },
      onSubmit: (state, dropin) => {


        //dropin.handleAction(action);
      }, // onSubmit configuration for card payments. Overrides the global configuration.
    }
  }
};

const checkout = new AdyenCheckout(configuration);


export default {
  name: 'App',
  components: {
    //Test2,
    //'myc': () => import("./Myc")
    VueJsonPretty
  },
  computed: {
    ...mapState(["studio", "contract", "addition"]),
    currentStep:{
        get(){
            return this.$store.state.currentStep;
        },
        set(value){
          this.$store.commit('SET_STEP', {step: parseInt(value)});
        }
    }
  },
  data(){
    return{
      debug: false,
      steps: [
        {
          headline:"Wähle deinen Club",
          pathname: "home",
          step: 0,
        },
        {
          headline:"Wähle dein Paket",
          pathname: "contract",
          step: 1,
        },
        {
          headline:"Wähle deinen Zusatz",
          pathname: "additional",
          step: 2,
        },
        {
          headline:"Deine Übersicht",
          pathname: "overview",
          step: 3,
        },
        {
          headline:"Kasse",
          pathname: "checkout_data",
          step: 4,
        },
        {
          headline:"Kasse",
          pathname: "checkout_address",
          step: 5,
        },
        {
          headline:"Kasse",
          pathname: "checkout_payment",
          step: 6,
        },
        {
          headline:"Kasse",
          pathname: "checkout_finish",
          step: 7,
        },
        {
          headline:"Kasse",
          pathname: "checkout_adyen",
          step: 8,
        }

      ],
      paymentData: null,
    }
  },
  mounted() {
    /* const dropin = checkout
        .create('dropin', {
          // Starting from version 4.0.0, Drop-in configuration only accepts props related to itself and cannot contain generic configuration like the onSubmit event.
          openFirstPaymentMethod:false
        })
        .mount('#dropin-container');*/

    /*let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute('src', 'https://app-wallee.com/assets/payment/lightbox-checkout-handler.js?spaceId=26568&transactionId=55349912&securityToken=1656624396369-rVBm38sHkzo0oIs47YVTzinKMJeZDIC20cfnt3qxkdA-1464855412534')
    document.head.appendChild(recaptchaScript);*/

    //if(this.$root.config.preSelectClub != undefined){
      this.axios.get('api/shop/studio/19', {params:{lang: this.$i18n.locale, tbcId: true}}).then(res => {
          if(res.data.studio){
            this.$store.dispatch("setStudio", {studio: res.data.studio});
            if(this.$route.name != 'failed' && this.$route.name != 'thankyou'){
                this.$store.commit("SET_STEP", {step: 1});
                this.$root.config.preSelectClub = null;
            }
          }
      });

   // }

    if(this.$root.config.modus != undefined && this.$root.config.modus == "employerApp"){
      this.steps[0] =         {
        headline:"Mitarbeiterlogin",
        pathname: "login",
        step: 0,
      };

      this.$store.commit("SET_STEP", {step: 1});
    }

    //console.log(this.$root.config);

    const html = document.documentElement // returns the html tag
    this.$i18n.locale = html.getAttribute('lang');

    if(this.$root.config.modus != undefined && this.$root.config.modus == "employerApp") {
      this.$router.push({name: 'login'});
    }
    else if(this.$route.name != 'failed' && this.$route.name != 'thankyou') {
      this.$router.push({name: 'home'});
    }
  },
  watch:{
    '$i18n.locale': function(newVal, oldVal) {
      this.$store.commit("SET_LOCALE", newVal);
    },
    '$route' (to, from){
      return true;
    },
    studio(newValue, oldValue){
      //this.stepOption1[0].value = newValue.name;
    },
    currentStep(){
      //this.initSteps();
      if(this.currentStep == undefined) return;
      //this.initSteps();
      this.$router.push({ name: this.steps[this.currentStep].pathname});
    },
  },
  methods:{
    payIt(){
      window.LightboxCheckoutHandler.startPayment(1, function(){
        alert('An error occurred during the initialization of the payment lightbox.');
      });
    },
    isClub($clubid){
        if(!this.$store.state.studio) return false;
        return this.$store.state.studio.externTbcId == $clubid;
    },
    stepOption1: undefined,
    pay(){
        this.axios.get("api/shop/order/payment").then(res =>{
              this.paymentData = res.data;
              setTimeout(()=>{
                this.$refs.payment.submit();
              },100);

        });
    },

    initSteps(){
      let step = this.currentStep;

      if(this.currentStep < 4){
        this.currentStepOptions = this.stepOption1;
      }
      else{
        this.currentStepOptions = this.stepOption2;
      }

      if(this.studio){
        this.stepOption1[0].value = this.studio.name;
      }
      
      if(this.contract){
        this.stepOption1[1].value = this.contract.Bezeichnung;
      }

      if(this.addition){
        this.stepOption1[2].value = this.addition.bezeichnung_current_language;
      }

      if(step > 0){
        this.stepOption1[0].active=false;
        this.stepOption1[0].finish=true;
        this.stepOption1[1].active=true;
      }
      if(step > 1){
        this.stepOption1[1].active=false;
        this.stepOption1[1].finish=true;
        this.stepOption1[2].active=true;
      }
      if(step > 2){
        this.stepOption1[2].active=false;
        this.stepOption1[2].finish=true;
        this.stepOption1[3].active=true;
      }

      if(step > 3){
        this.stepOption2[0].active=true;
      }
      if(step > 4){
        this.stepOption2[0].active=false;
        this.stepOption2[0].finish=true;
        this.stepOption2[1].active=true;
      }
      if(step > 5){
        this.stepOption2[1].active=false;
        this.stepOption2[1].finish=true;
        this.stepOption2[2].active=true;
      }
      if(step > 6){
        this.stepOption2[2].active=false;
        this.stepOption2[2].finish=true;
        this.stepOption2[3].active=true;
      }
    },
    onStudioSelect(studio){
        this.studio = studio;
    },
    onContractSelect(contract){
      //this.contract = contract;
    },
    onNextStep(){
      this.$store.commit("NEXT_STEP");

    },
    getStepHeadline(){
        return this.steps[this.currentStep].headline;
    },
    getFooterHeadline(){
      switch(this.currentStep){
        case 0:
          if(this.studio){
            return this.studio.name;
          }
          break;
        case 1:
          if(this.contract){
            return "CHF " + this.contract.vertBeitrag;
          }
          break;
        case 2:
          if(this.addition){
            return "CHF " + this.addition.zusaBeitrag;
          }
          break;
      }

      return null;
    },
    getFooterSubHeadline(){
      switch(this.currentStep){
        case 1:
          if(this.contract){
            return this.contract.Bezeichnung;
          }
          break;
        case 2:
          if(this.addition){
            return this.addition.bezeichnung_current_language;
          }
          break;
      }
      return null;
    },
    getFooterButtonEnabled(){
      switch(this.currentStep){
        case 0:
          if(this.studio){
            return true;
          }
          break;
        case 1:
          if(this.contract){
            return true;
          }
          break;
        case 2:
          return true;
      }

      return false;
    }
  }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
