<template>
  <div>
    <h1 class="text-center">Mitarbeiter Login</h1>
    <steps :steps="[]" />

    <ValidationObserver ref="form" v-slot="{ invalid }">
      <div class="container pt-5">
        <div class="row justify-content-center">
          <div class="col-sm-6">
                  <div class="alert alert-danger" v-if="errors">Login fehlgeschlagen!</div>
                  <div class="form-group">
                    <ValidationProvider name="User" rules="required" v-slot="v">
                      <input class="form-control" type="text" v-model="user" :class="{filled:user}" required>
                      <label>Benutzername</label>
                      <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="form-group">
                    <ValidationProvider name="Password" rules="required" v-slot="v">
                      <input class="form-control" type="password" v-model="password" :class="{filled:password}" required>
                      <label>Passwort</label>
                      <span class="error" v-if="v.errors">{{ v.errors[0] }}</span>
                    </ValidationProvider>
                  </div>

            <div class="form-group">
                <button :disabled="invalid" @click="login" class="btn btn-primary btn-block btn-xxl">Login</button>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import Steps from "@/components/Steps";
import {mapState} from "vuex";
import axios from "axios";



export default {
  name: "Login",
  components: {Steps},
  computed: {

  },
  data(){
    return{
      errors: false,
      user: null,
      password: null,
    }
  },
  watch:{

  },
  mounted(){
    if(this.$root.config.modus != "employerApp"){
      this.$router.push('studio');
      return;
    }
  },
  methods: {
    login(){
        this.errors = false,
        this.axios.post('api/shop/order/login', {user: this.user, password: this.password}).then(res => {
            if(!res.data.studio){
              this.errors = true;
              return true;
            }

          localStorage.setItem('X-MEN', res.data.token);
          axios.defaults.headers.common['X-MEN'] = localStorage.getItem('X-MEN');
          this.$store.dispatch("setStudio", {studio: res.data.studio});
          this.$store.commit("SET_STEP", {step: 1});

        }).catch(error => {
          this.errors = true;
        });
    }
  }
}

</script>

<style scoped>

</style>