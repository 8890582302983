<template>
  <footer class="fixed-bottom">
    <div class="container">
      <div class="row">
        <div class="col text-right">
          <div class="row">
            <div class="col align-self-center p-sm-0 text-left text-sm-right">
              <span v-if="headline" class="d-flex flex-column mainline">{{ headline }}</span>
              <span v-if="subheadline" class="d-flex flex-column subline">{{ subheadline }}</span>
            </div>
            <div class="col-6 col-sm-5 pl-2" v-if="!buttonHidden" :class="{'d-sm-none': buttonOnlyMobile}">
              <button @click="$emit('clickNext')" class="btn btn-primary btn-lg btn-block" :disabled="!buttonEnabled">
                {{ $t('common.next') }}</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    headline: [String],
    subheadline: [String],
    buttonEnabled: [Boolean],
    buttonHidden: [Boolean],
    buttonOnlyMobile: [Boolean],
  },
}
</script>

<style scoped>

</style>