<template>
  <div class="vCheckout4">
    <steps :steps="[]" />


    <div v-if="!payment" class="container">
      <div class="row">
        <div class="col-12">
          <div class="hr mx-0"><span>{{ $t('pages.checkOut.yourOverview') }}</span></div>
          <ul class="overviewList">
            <li>
              <span class="title">Studio:</span>
              <span class="value">{{ studio.name }} </span>
            </li>
            <li>
              <span class="title">Abo:</span>
              <span class="value"> {{ contract.Bezeichnung }} ({{ contract.vertBeitrag | formatCurrency }})</span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 1})">{{  $t('common.edit') }}</a>
            </li>
          </ul>
          <div class="hr mx-0"><span>{{ $t('pages.checkOut.yourData') }}</span></div>
          <ul class="overviewList">
            <li>
              <span class="title"><strong>{{$store.state.user.fName}} {{$store.state.user.lName}}</strong> </span>
              <span class="subvalue"><i>Adresse</i> {{$store.state.user.street}} {{$store.state.user.streetNo}}<br> {{$store.state.user.zipcode}} {{$store.state.user.city}}</span>
              <span class="subvalue"><i>Geburtstag</i> {{$store.state.user.birthday}}</span>
              <span class="subvalue"><i>Telefon</i> {{$store.state.user.phone}}</span>
              <span class="subvalue"><i>E-Mail</i> {{$store.state.user.email}}</span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 4})">{{  $t('common.edit') }}</a>
            </li>
            <li>
              <span class="title">{{ $t('pages.checkOut.yourMembershipStart') }}:</span>
              <span class="value"> {{ $store.state.order.membership.value }}<br class="d-sm-none"> ({{$t('widget.membership.trainingsStart', {date:getTrainingsStart()})}})</span>
              <a href="#" @click="$store.commit('SET_STEP', {step: 5})">{{  $t('common.edit') }}</a>
            </li>
          </ul>
        </div>
        <div class="col-12 mt-5">
          <div class="h-100 d-flex flex-column">
            <div class="hr mx-0"><span>Warenkorb</span></div>

            <div>
            <basket></basket>
            </div>
            <div class="row mt-3">
              <div class="col-8 pr-1">
                <div class="form-group  mb-0">
                  <input class="form-control" type="text" v-model="coupon.value" :class="{filled:coupon.value}" required>
                  <label>{{ $t('pages.checkOut.coupon') }}</label>
                </div>
              </div>
              <div class="col pl-1">
                <button class="btn btn-default btn-block" :disabled="!coupon.value" @click="sendCoupon">{{ $t('pages.checkOut.couponRedeem') }}</button>
              </div>
            </div>
            <div v-if="coupon.status < 0" class="text-danger">{{  $t('pages.checkOut.couponDecline', {coupon: coupon.value}) }}</div>
            <div v-if="$store.state.basket.coupon" class="text-success">
                {{  $t('pages.checkOut.couponAccept', {coupon: coupon.value}) }}
                <div v-if="$store.state.basket.coupontext">
                  {{ $store.state.basket.coupontext }}
                </div>
            </div>

            <label class="checkbox mt-3">
              <input type="checkbox" v-model="check1" value="1"><i></i>
              <i18n path="pages.checkOut.checkboxAGB" tag="span">
                <template v-slot:url_dataprotection>
                  <a :href="$root.config.urlPrivacy" target="_blank">{{ $t('pages.selectData.dataProtection') }}</a>
                </template>
                <template v-slot:url_agb>
                  <a :href="$root.config.urlAGB" target="_blank">{{ $t('pages.checkOut.checkboxURLAGB') }}</a>
                </template>
              </i18n>
            </label>
            <label class="checkbox" v-if="contract.vertkuendigungstext_current_language">
              <input type="checkbox"  v-model="check2" value="F"><i></i>
              <span>{{ contract.vertkuendigungstext_current_language }}</span>
            </label>
            <label class="checkbox" v-if="contract.vertaktionstext_current_language">
              <input type="checkbox"  v-model="check3" value="F"><i></i>
              <span>{{ contract.vertaktionstext_current_language }}</span>
            </label>
            <label class="checkbox">
              <input type="checkbox"  v-model="checkHouseRules"><i></i>
              <i18n path="pages.checkOut.checkHouseRules" tag="span">
                <template v-slot:houseRules>
                  <a :href="$root.config.urlHouseRules" target="_blank">{{ $t('pages.checkOut.houseRules') }}</a>
                </template>
              </i18n>
            </label>

            <div class="row my-3">
              <div class="col">
                <button class="btn btn-primary btn-block btn-xxl" @click="payIt()" :disabled="check()"><i v-if="sending" class="fas fa-sync fa-spin"></i> {{ $t('pages.checkOut.orderForFee') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="payment" class="container">
      <div class="row">
        <div class="col">
          <Payment v-if="payment"></Payment>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Steps from "@/components/Steps";
import { mapState } from "vuex";
import Payment from "@/components/Payment";

import Basket from "@/components/Basket";

export default {
  name: "Checkout4",
  components: {Basket, Steps, Payment},
  mounted() {
    this.$store.commit("SET_STEP", {step:7});
    this.recaptchaScript = document.createElement('script');
  },
  methods: {
    payIt(){
      this.sending = true;




      this.axios.post("api/shop/order/paymentWallee",{
        user: this.$store.state.user,
        contract: this.$store.state.contract,
        studio: this.$store.state.studio,
        order: this.$store.state.order,
        addition: this.$store.state.addition,
      }).then(res => {
        this.recaptchaScript.onload = () => {
          console.log(window.LightboxCheckoutHandler);
          window.LightboxCheckoutHandler.startPayment(1, function(){
            alert('An error occurred during the initialization of the payment lightbox.');
          });
        };

        this.recaptchaScript.setAttribute('src', res.data.uri)
        document.head.appendChild(this.recaptchaScript);
        this.sending = false;
      });

    },
    pay(){
      this.sending = true;
      this.axios.post("api/shop/order/payment",{
          user: this.$store.state.user,
          contract: this.$store.state.contract,
          studio: this.$store.state.studio,
          order: this.$store.state.order,
          addition: this.$store.state.addition,
      }).then(res =>{
        this.paymentData = res.data;

        if(this.paymentData.url == undefined && res.data.payment == true){
          this.$router.push('thankyou');
          this.sending = false;
        }
        else{
          setTimeout(()=>{
            this.$refs.payment.submit();
          },100);
        }
      }).catch(error =>{
        this.sending = false;
        alert('Ein unbekannter Fehler ist aufgetreten.');
      });
    },
    sendCoupon(){
      this.axios.post('api/shop/order/coupon', {coupon: this.coupon}, {params: {lang: this.$i18n.locale}}).then(res => {
        this.$store.commit('SET_BACKOFFICE', {order: res.data.order, basket: res.data.basket});
        if (typeof window.ga !== 'undefined'){window.ga('send', 'event', 'Bestellung', 'Gutschein hinzugefügt');}
        this.coupon.status = 1;
      }).catch(error => {
        this.coupon.status = -1;
      });
    },
    check(){
      if(this.sending) return true;
      if(!this.check1) return true;

      if(!this.checkHouseRules) return true;

      if(this.contract.vertkuendigungstext_current_language && !this.check2) return true;

      if(this.contract.vertaktionstext_current_language && !this.check3) return true;

      return false;
    },
    getTrainingsStart(){
      if(this.$store.state.order.membership.format == "DD.MM.YYYY"){
        return this.$store.state.order.membership.value;
      }

      if(this.$store.state.order.membership.value > this.$store.state.order.membership.defaultValue){
        return this.$store.state.order.membership.value;
      }

      return this.$store.state.order.accessdate.value;
      /*
      if(this.$store.state.order.membership.value > this.$store.state.order.membership.defaultValue){
        return this.$store.state.order.membership.value;
      }
      return this.$t('widget.membership.rightAway');*/
    }
  },
  watch:{
      'coupon.value'(value){
          this.coupon.status = 0;
      }
  },
  computed: mapState(["currentStep", "studio", "contract", "addition"]),
  data(){
    return{
      recaptchaScript: null,
      payment: false,
      paymentMethods: {
        "paypal": {active: true,name: "PayPal", id: "paypal", icon: "/shop/img/payments/paypal-logo.svg"},
        "visa":  {active: true,name: "Visa", id: "visa", icon: "/shop/img/payments/visa-logo.svg"},
        "mastercard":   {active: true,name: "Mastercard", id: "mastercard", icon: "/shop/img/payments/mastercard-logo.svg"},
        "postfinancecard":  {active: true,name: "Postfinancecard", id: "postfinancecard", icon: "/shop/img/payments/post-finance-logo.svg"},
        "postfinance":  {active: true, name: "Postfinance", id: "postfinance", icon: "/shop/img/payments/e-finance-logo.svg"},
        "ec":  {active: false, name: "Studio EC-Card", id: "ec", icon: "/shop/img/payments/ec-logo.svg"},
        "bar": {active: false, name: "Studio Bar-Zahlung", id: "bar", icon: "/shop/img/payments/bar-logo.svg"},
      },
      paymentData: null,
      check1: false,
      check2: false,
      check3: false,
      checkHouseRules: false,
      coupon: {
        value: "",
        status: 0,
      },
      sending: false,
    }
  }
}
</script>

<style scoped>

</style>